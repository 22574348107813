<template>
  <base-section
    id="pro-features2"
  >
    <v-container style="font-size: large">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
        >
          Программный комплекс автоматизации процесса подбора исполнителей на выполнение работ или услуг.
          Комплекс зарегистрирован в Реестре Российского программного обеспечения.

        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <a href="https://reestr.digital.gov.ru/reestr/947769/">
            Реестровая запись №14702 от 26.08.2022</a>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProFeat',

    data: () => ({
      features: [
        ['Components', 5, '40+'],
        ['Example Pages', 3, '10+'],
        ['Vue CLI Support', true, true],
        ['Bugfixes and Issues', false, true],
        ['6 Months Free Updates', false, true],
        ['Supports Vuetify', false, true],
        ['Price', 'Free', '$59'],
      ],
    }),
  }
</script>
